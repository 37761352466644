import addDays from 'date-fns/addDays';
import { useCallback, useMemo } from 'react';
import Button from 'src/components/common/Button';
import Dialog from 'src/components/common/Dialog';
import useA2HS from 'src/hooks/useA2HS';
import useRouteChangeListener from 'src/hooks/useRouteChangeListener';
import { getValueFromLocalStorage, setValueToLocalStorage } from 'src/utils/storage/localStorage';

const HIDE_INSTALL_PROMPT_UNTIL = 'hide_install_prompt_until';

interface Props {
  location: Window['location'];
}

export default function A2HS({ location }: Props) {
  const { promptEvent, install, clearPromptEvent } = useA2HS();

  const hideUntil = getValueFromLocalStorage<number>(HIDE_INSTALL_PROMPT_UNTIL);

  const visible = useMemo(() => !hideUntil || Date.now() > hideUntil, [hideUntil]);

  const handleCancel = useCallback(() => {
    if (visible && promptEvent) {
      clearPromptEvent();
      setValueToLocalStorage(HIDE_INSTALL_PROMPT_UNTIL, addDays(Date.now(), 7).getTime());
    }
  }, [clearPromptEvent, promptEvent, visible]);

  useRouteChangeListener(location, handleCancel);

  return promptEvent && visible ? (
    <Dialog positionForDesktop="bottom">
      <Dialog.Header>바로가기 추가</Dialog.Header>
      <Dialog.Body>카카오엔터테인먼트 FE 기술블로그 바로가기를 추가하시겠습니까?</Dialog.Body>
      <Dialog.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          취소
        </Button>
        <Button onClick={install}>추가하기</Button>
      </Dialog.Footer>
    </Dialog>
  ) : null;
}
