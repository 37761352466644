import noop from 'lodash/noop';
import { createContext, Fragment, PropsWithChildren, useCallback, useState } from 'react';

interface State {
  open: (modal: Modal<any>) => void;
  close: (id: string) => void;
}

export const ModalContext = createContext<State>({ open: noop, close: noop });

export function ModalProvider({ children }: PropsWithChildren<unknown>) {
  const [list, setList] = useState<Modal<any>[]>([]);

  const open = useCallback(<T,>(modal: Modal<T>) => {
    setList(prev => [...prev, modal]);
  }, []);

  const close = useCallback((id: string) => {
    setList(prev => prev.filter(modal => modal.id !== id));
  }, []);

  return (
    <ModalContext.Provider value={{ open, close }}>
      {children}
      {list.map(modal => (
        <Fragment key={modal.id}>{modal.content({ onRequestClose: modal.onRequestClose })}</Fragment>
      ))}
    </ModalContext.Provider>
  );
}
