import cn from 'classnames';
import { PropsWithChildren, MouseEvent } from 'react';
import DialogBody from 'src/components/common/DialogBody';
import DialogFooter from 'src/components/common/DialogFooter';
import DialogHeader from 'src/components/common/DialogHeader';
import * as styles from './styles/Dialog.module.scss';

interface Props {
  width?: number;
  dimmed?: boolean;
  onClose?: () => void;
  positionForDesktop?: 'center' | 'bottom';
}

function Dialog({
  positionForDesktop = 'center',
  width = 392,
  children,
  dimmed = false,
  onClose,
}: PropsWithChildren<Props>) {
  const handleClickDimmedLayer = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClose?.();
  };

  return (
    <div className={cn(styles.container, { [styles.enableAction]: !dimmed }, styles[positionForDesktop])}>
      <div className={cn({ [styles.dimmed]: dimmed })} onClick={handleClickDimmedLayer} />
      <div className={styles.dialog} style={{ width }}>
        {children}
      </div>
    </div>
  );
}

Dialog.Header = DialogHeader;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;

export default Dialog;
