import noop from 'lodash/noop';
import { createContext, PropsWithChildren, useCallback, useState } from 'react';

interface State {
  keywordOpened: boolean;
  toggleKeywordOpened: () => void;
}

export const UIContext = createContext<State>({ keywordOpened: false, toggleKeywordOpened: noop });

export function UIProvider({ children }: PropsWithChildren<unknown>) {
  const [keywordOpened, setKeywordOpened] = useState(false);

  const toggleKeywordOpened = useCallback(() => setKeywordOpened(prev => !prev), []);

  return <UIContext.Provider value={{ keywordOpened, toggleKeywordOpened }}>{children}</UIContext.Provider>;
}
