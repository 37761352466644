import { useEffect, useState } from 'react';

export default function useA2HS() {
  const [promptEvent, setPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handler = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setPromptEvent(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const clearPromptEvent = () => {
    setPromptEvent(null);
  };

  const install = () => {
    if (!promptEvent) {
      return;
    }

    promptEvent.prompt();
    promptEvent.userChoice.then(() => {
      clearPromptEvent();
    });
  };

  return { install, clearPromptEvent, promptEvent };
}
