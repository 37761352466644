function isEmpty(storage: Storage | undefined): storage is undefined {
  return !storage;
}

export type ValueType = boolean | number | string | object;

export interface SetValue {
  <T extends ValueType>(key: string, data: T): void;
}

export interface GetValue {
  <T extends ValueType>(key: string): T | undefined;
}

export const getValueFrom = (storage: Storage | undefined, key: string) => {
  if (isEmpty(storage)) {
    return;
  }
  const rawData = storage?.getItem(key);

  if (!rawData) {
    return;
  }
  return JSON.parse(rawData);
};

export const setValueTo = (storage: Storage | undefined, key: string, data: unknown) => {
  if (isEmpty(storage)) {
    return;
  }
  return storage.setItem(key, JSON.stringify(data));
};
