import cn from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import * as styles from './styles/Button.module.scss';

interface Props {
  variant?: 'primary' | 'secondary';
}

export default function Button({ variant = 'primary', ...props }: Props & ButtonHTMLAttributes<HTMLButtonElement>) {
  return <button className={cn(styles.button, styles[variant])} {...props}></button>;
}
