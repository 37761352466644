// custom typefaces
import 'typeface-noto-sans-kr';
import 'typeface-catamaran';
import { GatsbyBrowser } from 'gatsby';
import A2HS from 'src/components/common/A2HS';

import { PWA_PERIODIC_SYNC_TAG_NAME } from 'src/service-worker/constant';

import { ModalProvider } from 'src/state/ModalContext';
import { UIProvider } from 'src/state/UIContext';

import './src/styles/global.scss';
import isClient from 'src/utils/client';

interface PeriodicSyncManager {
  register(tag: string, options?: { minInterval: number }): Promise<void>;
}

declare global {
  interface ServiceWorkerRegistration {
    readonly periodicSync: PeriodicSyncManager;
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <>
      <UIProvider>
        <ModalProvider>{element}</ModalProvider>
      </UIProvider>
      <A2HS location={location} />
    </>
  );
};

/**
 * https://stackoverflow.com/questions/61598138/service-worker-in-gatsby-react-js
 * gatsby develop으로 실행시에는 service worker가 등록되지 않아서
 * develop으로 실행될 때 수동으로 service worker를 등록해야 함
 **/
export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'development' && isClient()) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/custom-sw.js');
    }
    registerServiceWorker();
  }
};

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(async registration => {
      if ('periodicSync' in registration) {
        const status = await navigator.permissions.query({
          name: 'periodic-background-sync' as PermissionName,
        });
        if (status.state === 'granted') {
          await registration.periodicSync.register(PWA_PERIODIC_SYNC_TAG_NAME, {
            minInterval: 24 * 60 * 60 * 1000,
          });
        }
      }

      await Notification.requestPermission();
    });
  }

  return true;
};
