import { useEffect, useRef } from 'react';

export default function useRouteChangeListener(location: Window['location'], handler: () => void) {
  const prevPath = useRef(location.pathname);

  useEffect(() => {
    if (prevPath.current === location.pathname) {
      return;
    }

    prevPath.current = location.pathname;
    handler();
  }, [handler, location]);
}
